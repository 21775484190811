<template>
  <div style="position: relative">
    <div class="breadcrumb-derek">
      <router-link
        :to="{
          name: 'TiendaCategory',
          params: { category: dataProduct.categoria_slug },
        }"
        class="navigate-to"
      >
        {{ dataProduct.categoria }}
      </router-link>
      <router-link
        :to="{
          name: 'TiendaSubcategory',
          params: {
            category: dataProduct.categoria_slug,
            subcategory: dataProduct.subcategoria_slug,
          },
        }"
        class="navigate-to"
      >
        {{ dataProduct.subcategoria }}
      </router-link>
      <p class="focus-page">
        {{
          dataProduct.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase())
        }}
      </p>
    </div>
    <div v-if="dataProduct.publicado == 0" class="out-of-stock-product">
      <span
        >Este producto no está disponible :( pero seguro encontrarás algo que te
        guste</span
      >
    </div>
    <div :key="color">
      <div class="py-3 px-4">
        <h2>
          {{
            dataProduct.titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </h2>
        <p style="font-size: 16px; color: #949494" class="my-3">
          SKU: {{ dataProduct.producto_sku }}
        </p>
        <p class="product-detail-price-movil m-0 pe-3">
          ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
          <del v-if="dataProduct.precio_antes > 0"
            >${{
              new Intl.NumberFormat('de-DE').format(dataProduct.precio_antes)
            }}</del
          >
        </p>
      </div>
      <div style="background-color: #f1f1f1; width: 100%;">
        <div
          v-for="(item, index) in refProduct.imagenes"
          :key="index"
          class="w-100 position-relative"
        >
          <img
            id="img-movil-product-detail"
            :src="item"
            :alt="item"
            class="img-product"
          />
          <div
            v-if="refProduct.modelo && index == 0"
            class="content-height-sizes-model-movil"
            :style="showMoreDetailsSize ? 'height: 50px;' : 'height: 30px;'"
          >
            <p class="mb-0">
              La modelo mide
              <strong class="px-1">{{ refProduct.modelo.altura }}</strong> y usa
              talla <strong class="px-1">{{ refProduct.modelo.talla }}</strong>
              <br />
              <span v-if="showMoreDetailsSize"
                >Su pecho mide {{ refProduct.modelo.pecho }} cm y su cintura,
                {{ refProduct.modelo.cintura }} cm.</span
              >
            </p>
            <img
              src="@/assets/icons/Shopicon.svg"
              @click="showMoreDetailsSize = !showMoreDetailsSize"
            />
          </div>
          <div
            v-if="refProduct.tags && index == 0"
            style="display: flex; flex-wrap: wrap; position: absolute; top: 0; left: 0"
          >
            <span
              v-for="(tag, index2) in refProduct.tags"
              :key="index2 + 'tag'"
              :style="
                `background-color: ${tag.color_fondo}; color: ${tag.color_texto};`
              "
              class="px-2 me-2 py-1 text-center"
            >
              {{ tag.tag }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="refProduct.outfit"
        id="outfit-product-detail"
        :class="refProduct.video ? 'mb-3' : ''"
      >
        <div class="img-outfit-detail">
          <img :src="refProduct.outfit.img_portada" />
          <span
            v-for="(coordenadas, index) in refProduct.outfit.productos"
            :key="index"
            class="pointer-product"
            :style="
              'top: ' +
                coordenadas.coordenada_y +
                '%; ' +
                'left: ' +
                coordenadas.coordenada_x +
                '%;'
            "
            @mouseover="
              productoSkuColor = coordenadas.producto_sku + coordenadas.color
            "
            @click="clicTooltip(coordenadas)"
          ></span>
        </div>
      </div>
      <div
        v-if="refProduct.video"
        class="video-product mb-0"
        @click="playVideo"
      >
        <div class="play-video py-3" style="aspect-ratio: auto;">
          <img
            src="@/assets/icons/play-video.svg"
            class="play-video-product-detail mb-0"
            alt=""
          />
          <h4 class="ms-2 mb-0">Ver video</h4>
        </div>
        <video
          v-if="refProduct.video"
          id="video-producto-movil"
          height="0"
          controls
          preload="metadata"
          loop
        >
          <source :src="refProduct.video" type="video/mp4" />
        </video>
      </div>
      <div v-if="refProduct.video">
        <video id="video_size_product_detail_movil" width="0" height="0" muted>
          <source :src="dataProduct.video_guia_tallas" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div
      class="product-detail-movil"
      :class="dataProduct.exclusivo_online ? 'mt-0' : ''"
    >
      <div
        v-if="dataProduct.exclusivo_online"
        class="container-exclisivo-online-product-detail"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="me-1"
          width="11.321"
          height="10.815"
          viewBox="0 0 11.321 10.815"
        >
          <path
            id="Icon_feather-star"
            data-name="Icon feather-star"
            d="M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z"
            transform="translate(-2.5 -2.5)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
        EXCLUSIVO ONLINE
      </div>
      <div class="rating-product-detail mb-3">
        <span>SKU: {{ dataProduct.producto_sku }}</span>
        <b-form-rating
          v-model="globalRating"
          readonly
          show-value
          variant="warning"
          precision="1"
          style="height: .88rem; margin-left: .5rem"
        ></b-form-rating>
      </div>
      <h2>
        {{
          dataProduct.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase())
        }}
      </h2>
      <div v-if="dataProduct.publicado == 1">
        <div v-if="dataProduct.dia_siniva == 1" class="d-flex my-3">
          <p class="product-detail-price">
            $
            {{
              new Intl.NumberFormat('de-DE').format(
                dataProduct.producto_precio_siniva,
              )
            }}
            <del
              >${{
                new Intl.NumberFormat('de-DE').format(dataProduct.precio)
              }}</del
            >
          </p>
          <div class="tags-product-detail ms-3">
            <div class="tag-dia-sin-iva">
              <p class="mb-0"><b>SIN IVA</b></p>
            </div>
          </div>
        </div>
        <div v-else class="d-flex flex-wrap align-items-center my-3">
          <p class="product-detail-price-movil my-3 pe-3">
            ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
            <del v-if="dataProduct.precio_antes > 0"
              >${{
                new Intl.NumberFormat('de-DE').format(dataProduct.precio_antes)
              }}</del
            >
          </p>
        </div>
        <div v-if="dataProduct.aplica_estrategia" class="d-flex flex-wrap mb-3">
          <span class="d-flex mr-2">
            <label
              :style="
                `background-color: ${dataProduct.descuento_estrategia.tag.tag_color_fondo}; color: ${dataProduct.descuento_estrategia.tag.tag_color_texto};`
              "
              style="font-size: 14px; padding: .1rem .4rem; font-weight: bold;"
              >{{ dataProduct.descuento_estrategia.tag.tag_texto }}</label
            >
          </span>
        </div>
        <div
          v-if="false"
          v-b-modal.modalcupo
          class="d-flex align-items-center px-1 py-2 mb-3"
          style="background-color: #FBEEE3; border-radius: 5px"
        >
          <img
            src="@/assets/icons/diamont-icon.svg"
            class="pe-3"
            alt="decorador-diamont"
          />
          <p class="mx-auto text-cupo-movil-product-detail">
            Hasta en {{ dataProduct.cant_cuotas }} cuotas quincenales de
            <strong
              >${{
                new Intl.NumberFormat('de-DE').format(dataProduct.cuota_cupo)
              }}*
            </strong>
          </p>
        </div>
        <div v-b-modal.modalcupo class="container-cuotas-quac movil">
          <div class="container-logo p-2">
            <img src="@/assets/icons/icon-quac.svg" alt="logo quac" />
          </div>
          <div class="col container-info-cuotas">
            <p>
              Págalo con Quac en hasta
              <b
                >{{ dataProduct.cant_cuotas }} cuotas quincenales de ${{
                  new Intl.NumberFormat('de-DE').format(dataProduct.cuota_cupo)
                }}.
              </b>
              <span> Más información</span>
            </p>
          </div>
        </div>
        <div class="box-colors-product-detail mb-3">
          <span
            >Color:
            <strong>{{
              refProduct.color_nombre
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}</strong></span
          >
          <div class="d-flex flex-wrap">
            <div
              v-for="(colorProduct, index) in dataProduct.referencias"
              :key="index"
              class="d-flex pe-2 mt-3"
              :class="colorProduct.habilitado ? '' : 'color-disabled'"
            >
              <div
                class="contenedor-colors-product-detail"
                :class="
                  colorProduct.color_id == color
                    ? 'color-selected-product-detail'
                    : ''
                "
                @click="
                  colorProduct.habilitado
                    ? $emit('changeColor', colorProduct.color_id)
                    : ''
                "
              >
                <div
                  class="colors-product-detail"
                  :style="
                    colorProduct.color_textura != ''
                      ? 'background-image: url(' +
                        colorProduct.color_textura +
                        ')'
                      : 'background-color:' + colorProduct.color_hx
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="refProduct.habilitado" class="box-sizes-product-detail">
          <span
            >Talla:
            <strong>{{
              refProduct.tallas.length == 1 && size == 'UN'
                ? refProduct.tallas[0].name
                : ''
            }}</strong></span
          >
          <button
            v-if="dataProduct.tipo_prenda_descripcion"
            class="btn-guia-tallas"
            style="background-color: transparent"
            @click="$store.state.showSizeGuide = true"
          >
            Guía de tallas
          </button>
          <div
            v-if="refProduct.tallas.length > 1 && size != 'UN'"
            class="select-size-product-movil"
          >
            <div
              v-for="(sizeProduct, index) in refProduct.tallas"
              :key="index"
              class="d-flex pe-3"
            >
              <span
                :title="
                  sizeProduct.stock == 0
                    ? 'Talla agotada'
                    : 'Talla: ' + sizeProduct.name
                "
                :style="
                  missingSize
                    ? `animation-name: missingSize; animation-duration: .5s;`
                    : ''
                "
                class="sizes-product"
                :class="
                  sizeProduct.id == size
                    ? 'size-selected'
                    : sizeProduct.stock == 0
                    ? 'size-no-aviable'
                    : ''
                "
                @click="
                  sizeProduct.stock > 0 ? selectSizeProduct(sizeProduct) : ''
                "
                >{{ sizeProduct.name }}</span
              >
            </div>
          </div>
          <span v-if="msgError" class="msgError">Selecciona una talla</span>
        </div>
        <div v-else style="background-color: #FFF4DE" class="px-3 py-2 mb-4">
          <p style="color: #654062;" class="mb-0 text-center">
            <b>Este producto no está disponible en este color</b>
          </p>
        </div>
        <p class="text-description-product-detail pe-0 mt-4 w-100">
          {{
            dataProduct.descripcion
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
          <br />
          Pais origen:
          {{
            dataProduct.origen
              ? dataProduct.origen
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              : '*'
          }}
          - Fabricante / Importador:
          {{
            dataProduct.importador
              ? dataProduct.importador
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              : '*'
          }}
          <br />
          <span v-if="dataProduct.composicion">
            Composición:
            {{
              dataProduct.composicion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </span>
        </p>
      </div>
      <div v-if="dataProduct.publicado == 0">
        <div class="rating-product-detail mb-2">
          <span>SKU: {{ dataProduct.producto_sku }}</span>
          <b-form-rating
            v-model="globalRating"
            readonly
            show-value
            variant="warning"
            precision="1"
            style="heigth: .88rem"
          ></b-form-rating>
        </div>
        <p
          v-if="dataProduct.descripcion"
          class="text-description-product-detail pe-0 me-0"
        >
          {{
            dataProduct.descripcion
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
          <br />
          Pais origen:
          {{
            dataProduct.origen
              ? dataProduct.origen
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              : '*'
          }}
          - Fabricante / Importador:
          {{
            dataProduct.importador
              ? dataProduct.importador
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              : '*'
          }}
          <br />
          <span v-if="dataProduct.composicion">
            Composición:
            {{
              dataProduct.composicion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </span>
        </p>
        <div class="product-not-stock">
          <p class="fs-6">PRODUCTO NO DISPONIBLE</p>
          <p class="fs-6">
            Este artículo se ha agotado, pero no te preocupes regístrate y te
            avisaremos de nuestros nuevos lanzamientos, ofertas exclusivas y
            descuentos de cumpleaños.
          </p>
          <div class="col-md-10 mx-auto col-xl-8">
            <div class="mb-auto">
              <div class="date-newslatter-modal">
                <label class="label-text-newlatter-birthday w-100"
                  >Fecha de cumpleaños</label
                >
                <select
                  v-model="day"
                  :class="!day && validateDateNewslatter ? 'input-error' : ''"
                  class="payment-input me-3"
                >
                  <option :value="null">Día</option>
                  <option :value="'01'">1</option>
                  <option :value="'02'">2</option>
                  <option :value="'03'">3</option>
                  <option :value="'04'">4</option>
                  <option :value="'05'">5</option>
                  <option :value="'06'">6</option>
                  <option :value="'07'">7</option>
                  <option :value="'08'">8</option>
                  <option :value="'09'">9</option>
                  <option :value="'10'">10</option>
                  <option :value="'11'">11</option>
                  <option :value="'12'">12</option>
                  <option :value="'13'">13</option>
                  <option :value="'14'">14</option>
                  <option :value="'15'">15</option>
                  <option :value="'16'">16</option>
                  <option :value="'17'">17</option>
                  <option :value="'18'">18</option>
                  <option :value="'19'">19</option>
                  <option :value="'20'">20</option>
                  <option :value="'21'">21</option>
                  <option :value="'22'">22</option>
                  <option :value="'23'">23</option>
                  <option :value="'24'">24</option>
                  <option :value="'25'">25</option>
                  <option :value="'26'">26</option>
                  <option :value="'27'">27</option>
                  <option :value="'28'">28</option>
                  <option :value="'29'">29</option>
                  <option :value="'30'">30</option>
                  <option :value="'31'">31</option>
                </select>
                <select
                  v-model="month"
                  :class="!month && validateDateNewslatter ? 'input-error' : ''"
                  class="payment-input ms-3"
                >
                  <option :value="null">Mes</option>
                  <option :value="'01'">Enero</option>
                  <option :value="'02'">Febrero</option>
                  <option :value="'03'">Marzo</option>
                  <option :value="'04'">Abril</option>
                  <option :value="'05'">Mayo</option>
                  <option :value="'06'">Junio</option>
                  <option :value="'07'">Julio</option>
                  <option :value="'08'">Agosto</option>
                  <option :value="'09'">Septiembre</option>
                  <option :value="'10'">Octubre</option>
                  <option :value="'11'">Noviembre</option>
                  <option :value="'12'">Diciembre</option>
                </select>
                <span
                  v-if="day"
                  class="newlatter-error-input"
                  :style="!day && validateDateNewslatter ? 'color: red' : ''"
                  >Día</span
                >
                <span
                  v-if="month"
                  class="newlatter-error-input"
                  style="left: calc(50% + 1.5rem);"
                  :style="!month && validateDateNewslatter ? 'color: red' : ''"
                  >Mes</span
                >
              </div>
              <div class="position-relative">
                <input
                  id="email"
                  v-model="correo"
                  class="payment-input w-100 mb-3"
                  :class="validateEmailNewslatter ? 'input-error' : ''"
                  placeholder="Correo electrónico"
                  type="email"
                  name="correo"
                />
                <span
                  v-if="correo"
                  class="newlatter-error-input"
                  :style="validateEmailNewslatter ? 'color: red' : ''"
                  >correo</span
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <label class="d-flex mx-0 ms-md-1">
                  <input
                    v-model="cel_newslatter"
                    class="tyc-checkbox"
                    type="checkbox"
                    checkmark="checkmark"
                  />
                  <span class="checkmark"></span>
                </label>
                <span style="font-size: 14px">Me gustaría recibir SMS.</span>
              </div>
            </div>
            <div
              v-if="cel_newslatter"
              class="input-tel-newslatter w-100 my-3 position-relative"
            >
              <label
                class="tel-newslatter"
                :class="validateTelNewslatter ? 'input-error' : ''"
                :style="validateTelNewslatter ? 'padding: 17px' : ''"
                >+57</label
              >
              <input
                v-model="tel"
                class="payment-input w-100"
                :class="validateTelNewslatter ? 'input-error' : ''"
                placeholder="Celular"
                type="tel"
                name="celular"
              />
              <span
                v-if="tel"
                class="newlatter-error-input"
                style="left: 4rem"
                :style="validateTelNewslatter ? 'color: red' : ''"
                >Telefono</span
              >
            </div>
            <div class="text-center w-100 my-4">
              <span class="msgError">{{ msgerrorNewsLatter }}</span>
            </div>
            <div class="mt-auto w-100">
              <div class="col-6 mx-auto pt-2 pb-3">
                <button class="btn-primary w-100" @click="sendNewsLatter()">
                  Suscribirme
                </button>
              </div>
              <div class="text-center">
                <p class="tyc-newslatter mb-0" style="font-size: 1rem">
                  Al suscribirte, aceptas nuestros
                  <router-link
                    style="font-size: 1rem; color: #62285e;"
                    target="_blank"
                    :to="{
                      name: 'Politicas',
                      params: { slug: 'politica-de-privacidad' },
                    }"
                    >Términos y condiciones y Política de tratamiento de
                    datos.</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <message-errors
      v-if="showMsgFewUnits"
      :msg-error="msgFewUnits"
      style="margin-top: 0; position: relative; padding: 0;"
    />
    <div v-if="dataProduct.publicado == 1">
      <div
        id="container-btn-add-cart-movil"
        style="height: 82px; margin-top: 1.5rem"
      >
        <div
          v-if="floatingButton && showSelectSize"
          class="bg-page-btn-add-floating"
          @click="showSelectSize = false"
        />
        <div v-if="msgErrorAddProduct" class="text-center relative">
          <span class="msg-error-add-product" style="top: 0 !important;">
            {{ msgErrorAddProduct }}
          </span>
        </div>
        <div
          v-if="validateColor"
          id="id-btn-add-cart-movil"
          class="btn-add-cart-movil"
          :style="
            floatingButton && showSelectSize ? 'background-color: white' : ''
          "
        >
          <div
            v-if="floatingButton && showSelectSize"
            :class="
              floatingButton && showSelectSize
                ? 'container-floating-select-size'
                : ''
            "
          >
            <img
              class="btn-close-card-sizes-by-btn"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="showSelectSize = false"
            />
            <p class="product-detail-price-movil mb-3">
              ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
              <del v-if="dataProduct.precio_antes > 0"
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    dataProduct.precio_antes,
                  )
                }}</del
              >
            </p>
            <div class="paga-cuotas-btn-floating">
              <img
                class="me-auto"
                src="@/assets/icons/diamont-icon.svg"
                alt="decorador-diamont"
              />
              <p class="text-cupo-movil-product-detail">
                {{ dataProduct.cant_cuotas }} cuotas quincenales de
              </p>
              <p class="text-cupo-movil-product-detail bold ps-1">
                ${{
                  new Intl.NumberFormat('de-DE').format(dataProduct.cuota_cupo)
                }}*
              </p>
            </div>
            <span>Selecciona tu talla</span>
            <div class="select-size-product-movil">
              <div
                v-for="(sizeProduct, index) in refProduct.tallas"
                :key="index"
                class="d-flex pe-3"
              >
                <span
                  :title="
                    sizeProduct.stock == 0
                      ? 'Talla agotada'
                      : 'Talla: ' + sizeProduct.name
                  "
                  :style="
                    missingSize
                      ? `animation-name: missingSize; animation-duration: .5s;`
                      : ''
                  "
                  class="sizes-product"
                  :class="
                    sizeProduct.id == size
                      ? 'size-selected'
                      : sizeProduct.stock == 0
                      ? 'size-no-aviable'
                      : ''
                  "
                  @click="
                    sizeProduct.stock > 0
                      ? [
                          (msgError = false),
                          $emit('changeSize', sizeProduct.id),
                        ]
                      : ''
                  "
                  >{{ sizeProduct.name }}</span
                >
              </div>
            </div>
            <span v-if="msgError" class="msgError">Selecciona una talla</span>
          </div>
          <div class="d-flex">
            <button-fav
              :data-product="dataProduct"
              class="btn-fav-product-detail"
            />
            <button
              type=""
              class="btn-primary btn-add-cart-product-detail"
              @click="agregarProducto"
            >
              <div v-if="!loading">
                <img src="@/assets/icons/shopping-bag.svg" alt="" />{{
                  size ? 'Agregar a la bolsa' : 'Elige una talla primero'
                }}
              </div>
              <div v-else class="d-flex justify-content-center">
                Cargando...
                <div class="loader" />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="dataProduct.estrategia_venta"
        class="mx-4 my-4 estrategia-product-detail"
      >
        <div class="text-center col-12">
          <h5 class="mb-1">{{ dataProduct.estrategia_venta.nombre }}</h5>
          <p class="fs-6 mb-0">
            {{ dataProduct.estrategia_venta.descripcion }}
          </p>
          <a class="ms-auto" :href="dataProduct.estrategia_venta.url_analytics">
            <button
              style="font-size: .875rem"
              class="mt-3 w-100 px-4 btn-primary"
            >
              Ver más
            </button>
          </a>
        </div>
      </div>
    </div>
    <sizes-guide-product-detail
      v-if="$store.state.showSizeGuide"
      :type-screem="'movil'"
      :type-of-garment="dataProduct.tipo_prenda_descripcion"
      @closeSizeGuide="$store.state.showSizeGuide = false"
    />
    <add-to-cart />
  </div>
</template>
<script>
import SizesGuideProductDetail from '@/components/ProductDetail/SizesGuideProductDetail.vue'
import ButtonFav from '@/components/Templates/ButtonFav.vue'
import AddToCart from '@/components/ProductDetail/AddToCart.vue'
import MessageErrors from '@/components/Templates/MessageErrors.vue'

import { GA4AddToCart, GA4SignUp } from '@/GA4/events'
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { SizesGuideProductDetail, ButtonFav, MessageErrors, AddToCart },
  props: {
    dataProduct: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showMsgFewUnits: false,
      msgFewUnits: '',
      loading: false,
      missingSize: false,
      msgError: false,
      // Se declaran variables para controlar el mostrar el modal!
      floatingButton: false,
      showSelectSize: false,
      emailNewsLatter: '',
      validateNewsLatter: false,
      correo: '',
      validateEmailNewslatter: false,
      day: null,
      month: null,
      validateDateNewslatter: false,
      tel: '',
      validateTelNewslatter: false,
      cel_newslatter: false,
      primerClickMovil: false,
      dataCarouselIMG: {
        pos: 0,
        scrollY: 0,
        offset: 0,
        blockScroll: false,
      },
      timerScrollIMG: null,
      showMoreDetailsSize: false,
    }
  },
  computed: {
    ...mapState(['showSizeGuide']),
    ...mapState('cart', ['msgErrorAddProduct']),
    ...mapState('user', ['errorNewsLatter', 'msgerrorNewsLatter']),
    refProduct() {
      return this.dataProduct.referencias.find(e => e.color_id == this.color)
    },
    globalRating() {
      let total = 0.0
      for (var i = 0; i < this.dataProduct.valoraciones.length; i++) {
        total += parseFloat(this.dataProduct.valoraciones[i].puntaje)
      }
      return (
        (total + 225) /
        (this.dataProduct.valoraciones.length + 50)
      ).toFixed(1)
    },
    aspect8_9() {
      let height = 'height: auto'
      height = 'max-height:' + (window.innerWidth / 8) * 9 + 'px;'
      return height
    },
    aspectimg() {
      return (window.innerWidth / 8) * 9
    },
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      if (!this.cel_newslatter) {
        return true
      } else {
        const re = /^3[0-9]{9}/
        return re.test(this.tel) && this.tel.length < 11
      }
    },
    validateColor() {
      let showColor = false
      if (this.refProduct.tallas) {
        this.refProduct.tallas.forEach(e => {
          if (e.stock > 0) {
            showColor = true
          }
        })
      }
      return showColor
    },
  },
  mounted() {
    window.addEventListener('scroll', this.visibleFooter)
    // document.getElementById("container-imgs-product").addEventListener('scroll', this.caroseulImagenesMovil);
    document
      .getElementById('id-btn-add-cart-movil')
      ?.classList.add('btn-add-cart-movil-fixed')
    this.floatingButton = true
    this.showSelectSize = false
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.visibleFooter)
  },
  async created() {
    if(this.size){
      const sizeRefSel = this.refProduct.tallas.find(e => e.id == this.size)
      if(sizeRefSel){
        if(sizeRefSel.alerta_stock){
          this.showMsgFewUnits = true
          if(sizeRefSel.stock == 1){
            this.msgFewUnits = '🔥 ¡Última unidad disponible!'
          }else{
            this.msgFewUnits = '🔥 ¡Últimas '+sizeRefSel.stock+' unidades disponibles!'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('user', ['suscribeDerek']),
    selectSizeProduct(sizeProduct){
      this.msgError = false
      this.$emit('changeSize', sizeProduct.id)
      if(sizeProduct.alerta_stock == 1){
        this.showMsgFewUnits = true
        if(sizeProduct.stock == 1){
          this.msgFewUnits = '🔥 ¡Última unidad disponible!'
        }else{
          this.msgFewUnits = '🔥 ¡Últimas '+sizeProduct.stock+' unidades disponibles!'
        }
      }else{
        this.showMsgFewUnits = false
        this.msgFewUnits = ''
      }
    },
    async agregarProducto() {
      if (this.size) {
        let elThis = this
        this.loading = true
        let dataProductAddToCart = {
          size: this.size,
          color: this.color,
          sku: this.dataProduct.producto_sku,
        }
        this.addToCart(dataProductAddToCart).then(() => {
          if (this.msgErrorAddProduct == '') {
            GA4AddToCart(this.dataProduct.producto_sku)
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.dataProduct.producto_sku,
              value: this.dataProduct.precio,
              content_type: 'product',
              content_name:
                this.dataProduct.categoria_slug.toUpperCase() + ' DEREK',
              content_category: this.dataProduct.categoria_slug.toUpperCase(),
              content_ids:
                this.dataProduct.producto_sku + this.color + this.size,
              currency: 'COP',
            })
            const misCookies = document.cookie.split(';')
            let email = misCookies.find(e => {
              return e.includes('email')
            })
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 't'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                  navigator.userAgent,
                )
              ? 'm'
              : 'd'
            window.criteo_q.push(
              { event: 'setAccount', account: 67539 },
              {
                event: 'setEmail',
                email: email
                  ? email.replace('email=', '')
                  : this.dataUser
                  ? this.dataUser.correo
                  : '',
              },
              { event: 'setSiteType', type: deviceType },
              { event: 'setZipcode', zipcode: '##Zip Code##' },
              { event: 'addToCart', item: [this.dataProduct.producto_sku] },
            )
            elThis.$bvModal.show('modal-add-to-cart')
          }
          this.loading = false
        })
      } else {
        if (this.primerClickMovil || !this.floatingButton) {
          this.msgError = true
          this.missingSize = true
          this.showSelectSize = true
          setTimeout(() => {
            this.missingSize = false
          }, 1000)
        } else {
          this.primerClickMovil = true
          this.showSelectSize = true
          if (innerWidth > 768) {
            this.msgError = true
            this.missingSize = true
            await setTimeout(() => {
              this.missingSize = false
            }, 1000)
          }
        }
      }
    },
    playVideo() {
      let video = document.getElementById('video-producto-movil')
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen()
      }
      video.play()
      video.currentTime = 0
    },
    visibleFooter() {
      let footer = document.querySelector('#footer')
      let element = document.querySelector('#product-detail-options')
      let positionFooter = footer ? footer.getBoundingClientRect() : false
      let positionElement = element ? element.getBoundingClientRect() : false
      let btn_actions = document.querySelector('#container-btn-add-cart-movil')
      let positionFooterBTN = btn_actions
        ? btn_actions.getBoundingClientRect()
        : false
      if (
        positionFooter &&
        (positionFooterBTN.top + 70 < window.innerHeight ||
          positionFooter.top < window.innerHeight ||
          positionElement.top < window.innerHeight) &&
        positionFooter.bottom >= 0
      ) {
        this.floatingButton = false
        if (this.showSelectSize == true) {
          this.showSelectSize = false
        }
        document
          .getElementById('id-btn-add-cart-movil')
          ?.classList.remove('btn-add-cart-movil-fixed')
      } else {
        this.floatingButton = true
        this.showSelectSize = false
        document
          .getElementById('id-btn-add-cart-movil')
          ?.classList.add('btn-add-cart-movil-fixed')
      }
    },
    async sendNewsLatter() {
      if (this.day && this.month && this.validatePhone && this.validateEmail) {
        this.validateEmailNewslatter = false
        this.validateDateNewslatter = false
        this.validateTelNewslatter = false
        await this.suscribeDerek({
          correo: this.correo,
          day: this.day,
          month: this.month,
          sms: this.cel_newslatter ? 1 : 0,
          tel: this.tel ? this.tel : '',
        }).then(() => {
          if (!this.msgerrorNewsLatter) {
            document.cookie = 'email=' + this.correo
            this.$bvModal.show('modal-done-newsletter')
            this.$bvModal.hide('modal-newsletter')
            sendFBQEvent(this, 'Subscribe', {})
            GA4SignUp('NewsLatter')
            this.correo = ''
            this.stepNewslatter += 1
          }
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
    clicTooltip(coordenadas) {
      let productAnalitycs = this.refProduct.outfit.productos_analytics?.find(
        e => e.id == coordenadas.producto_sku,
      )
      this.$emit('selectProductOutfit', coordenadas)
      setTimeout(() => this.$bvModal.show('modal-product-detail-' + coordenadas.producto_sku), 100)
      if (productAnalitycs) {
        // DK-VentaCruzada
        // window.dataLayer.push({
        //   event: 'DK-VentaCruzada',
        //   productoPadre: this.dataProduct.titulo,
        //   productoPadreSKU: this.dataProduct.producto_sku,
        //   item: productAnalitycs,
        //   value: productAnalitycs.price,
        // })
        // this.$gtag.event('productClick', {
        //   ecommerce: {
        //     click: {
        //       actionField: { list: productAnalitycs.category },
        //       products: [
        //         {
        //           name: productAnalitycs.name,
        //           id: productAnalitycs.id,
        //           price: productAnalitycs.price,
        //           brand: productAnalitycs.brand,
        //           category: productAnalitycs.category,
        //           variant: productAnalitycs.variant,
        //           position: productAnalitycs.position,
        //         },
        //       ],
        //     },
        //   },
        // })
      }
    },
    getDataAnalytics(productos) {
      let arrayTemp = []
      productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          SKU: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
}
</script>
