<template>
  <div class="product-detail">
    <div class="breadcrumb-derek">
      <router-link
        :to="{
          name: 'TiendaCategory',
          params: { category: dataProduct.categoria_slug },
        }"
        class="navigate-to"
      >
        {{ dataProduct.categoria }}
      </router-link>
      <router-link
        :to="{
          name: 'TiendaSubcategory',
          params: {
            category: dataProduct.categoria_slug,
            subcategory: dataProduct.subcategoria_slug,
          },
        }"
        class="navigate-to"
      >
        {{ dataProduct.subcategoria }}
      </router-link>
      <p class="focus-page">
        {{
          dataProduct.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase())
        }}
      </p>
    </div>
    <div v-if="dataProduct.publicado == 0" class="out-of-stock-product">
      <span
        >Este producto no está disponible :( pero seguro encontrarás algo que te
        guste</span
      >
    </div>
    <div :key="color" class="col-12 col-md-6 product-detail-img">
      <div class="tags-product-detail">
        <span
          v-for="(tag, index) in refProduct.tags"
          :key="index + 'tag'"
          :style="
            `background-color: ${tag.color_fondo}; color: ${tag.color_texto};`
          "
          class="px-2 py-1 text-center me-2"
        >
          {{ tag.tag }}
        </span>
      </div>
      <img
        v-for="(item, index) in refProduct.imagenes"
        :key="index"
        :src="item"
        :alt="dataProduct.titulo + ' ' + refProduct.color_nombre + ' ' + index"
        class="img-product"
      />
      <div v-if="refProduct.outfit" id="outfit-product-detail">
        <div class="img-outfit-detail">
          <img :src="refProduct.outfit.img_portada" alt="" />
          <span
            v-for="(coordenadas, index) in refProduct.outfit.productos"
            :key="index"
            v-b-popover.hover.top="coordenadas.producto_titulo"
            class="pointer-product"
            :style="
              'top: ' +
                coordenadas.coordenada_y +
                '%; ' +
                'left: ' +
                coordenadas.coordenada_x +
                '%;'
            "
            @mouseover="
              productoSkuColor = coordenadas.producto_sku + coordenadas.color
            "
            @click="clicTooltip(coordenadas)"
          ></span>
        </div>
      </div>
      <div
        v-if="refProduct.modelo"
        class="content-height-sizes-model"
        :style="showMoreDetailsSize ? 'height: 60px;' : 'height: 30px;'"
      >
        <p class="mb-0">
          La modelo mide
          <strong class="px-1">{{ refProduct.modelo.altura }}</strong> y usa
          talla <strong class="px-1">{{ refProduct.modelo.talla }}</strong>
          <br />
          <span v-if="showMoreDetailsSize"
            >Su pecho mide {{ refProduct.modelo.pecho }} cm y su cintura,
            {{ refProduct.modelo.cintura }} cm.</span
          >
        </p>
        <img
          src="@/assets/icons/Shopicon.svg"
          @click="showMoreDetailsSize = !showMoreDetailsSize"
        />
      </div>
      <div
        v-if="refProduct.video"
        class="video-product"
        style="max-width: none;"
        @click="playVideo"
      >
        <div class="play-video py-3" style="aspect-ratio: auto;">
          <img
            src="@/assets/icons/play-video.svg"
            class="play-video-product-detail mb-0 me-2"
            alt=""
          />
          <h4 class="mb-0 text-center">Ver video</h4>
        </div>
      </div>
      <video
        v-if="refProduct.video"
        id="video-producto"
        :alt="'Video ' + dataProduct.titulo + ' ' + refProduct.color_nombre"
        height="0"
        controls
        preload
        loop
      >
        <source :src="refProduct.video" type="video/mp4" />
      </video>
    </div>
    <div class="col-12 col-md-6">
      <div
        id="id-product-detail-data"
        class="product-detail-data"
        :class="dataProduct.exclusivo_online ? 'pt-0' : ''"
      >
        <div
          v-if="dataProduct.exclusivo_online"
          class="container-exclisivo-online-product-detail"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="me-1"
            width="11.321"
            height="10.815"
            viewBox="0 0 11.321 10.815"
          >
            <path
              id="Icon_feather-star"
              data-name="Icon feather-star"
              d="M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z"
              transform="translate(-2.5 -2.5)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
          </svg>
          EXCLUSIVO ONLINE
        </div>
        <h3 class="title-product-detail">
          {{
            dataProduct.titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </h3>
        <div class="rating-product-detail">
          <span>SKU: {{ dataProduct.producto_sku }}</span>
          <b-form-rating
            v-model="globalRating"
            readonly
            show-value
            variant="warning"
            precision="1"
            style="heigth: .88rem"
          ></b-form-rating>
        </div>
        <div v-if="dataProduct.publicado == 1" style="position: relative;">
          <div v-if="dataProduct.dia_siniva == 1" class="d-flex flex-wrap my-3">
            <p class="product-detail-price">
              $
              {{
                new Intl.NumberFormat('de-DE').format(
                  dataProduct.producto_precio_siniva,
                )
              }}
              <del
                >${{
                  new Intl.NumberFormat('de-DE').format(dataProduct.precio)
                }}</del
              >
            </p>
            <div class="tags-product-detail ms-3">
              <div class="tag-dia-sin-iva">
                <p class="mb-0"><b>SIN IVA</b></p>
              </div>
            </div>
          </div>
          <div v-else class="d-flex flex-wrap align-items-center mt-3">
            <p class="product-detail-price pb-0 mt-0 pe-3">
              $ {{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
              <del v-if="dataProduct.precio_antes > 0"
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    dataProduct.precio_antes,
                  )
                }}</del
              >
            </p>
          </div>
          <div v-if="dataProduct.aplica_estrategia" class="d-flex flex-wrap">
            <span class="d-flex pt-2 mr-2">
              <label
                :style="
                  `background-color: ${dataProduct.descuento_estrategia.tag.tag_color_fondo}; color: ${dataProduct.descuento_estrategia.tag.tag_color_texto};`
                "
                style="font-size: 14px; padding: .1rem .4rem; font-weight: bold;"
                >{{ dataProduct.descuento_estrategia.tag.tag_texto }}</label
              >
            </span>
          </div>
          <div v-b-modal.modalcupo class="container-cuotas-quac">
            <div class="container-logo p-2">
              <img src="@/assets/icons/icon-quac.svg" alt="logo quac" />
            </div>
            <div class="col container-info-cuotas">
              <p>
                Págalo con Quac en hasta
                <b
                  >{{ dataProduct.cant_cuotas }} cuotas quincenales de ${{
                    new Intl.NumberFormat('de-DE').format(
                      dataProduct.cuota_cupo,
                    )
                  }}.
                </b>
                <span> Más información</span>
              </p>
            </div>
          </div>
          <div
            class="box-colors-product-detail mb-3"
            :class="!validateColor ? 'mb-5' : ''"
          >
            <span
              >Color:
              <strong>{{
                refProduct.color_nombre
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}</strong></span
            >
            <div class="d-flex flex-wrap">
              <div
                v-for="(colorProduct, index) in dataProduct.referencias"
                :key="index"
                class="d-flex pe-2 mt-3"
                :title="
                  colorProduct.habilitado
                    ? colorProduct.color_nombre
                    : 'No disponible'
                "
                :class="colorProduct.habilitado ? '' : 'color-disabled'"
              >
                <div
                  class="contenedor-colors-product-detail"
                  :class="
                    colorProduct.color_id == color
                      ? 'color-selected-product-detail'
                      : ''
                  "
                  @click="
                    colorProduct.habilitado
                      ? $emit('changeColor', colorProduct.color_id)
                      : ''
                  "
                >
                  <div
                    class="colors-product-detail"
                    :style="
                      colorProduct.color_textura != ''
                        ? 'background-image: url(' +
                          colorProduct.color_textura +
                          ')'
                        : 'background-color:' + colorProduct.color_hx
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="refProduct.habilitado" class="box-sizes-product-detail">
            <span
              >Talla:
              <strong>{{
                refProduct.tallas.length == 1 && refProduct.tallas[0].id == 'UN'
                  ? refProduct.tallas[0].name
                  : ''
              }}</strong></span
            >
            <div
              v-if="
                !(
                  refProduct.tallas.length == 1 &&
                  refProduct.tallas[0].id == 'UN'
                )
              "
              class="select-size-product"
            >
              <div class="d-flex">
                <div
                  v-for="(sizeProduct, index) in refProduct.tallas"
                  :key="index"
                  class="d-flex pe-3"
                >
                  <span
                    :title="
                      sizeProduct.stock == 0
                        ? 'Talla agotada'
                        : 'Talla: ' + sizeProduct.name
                    "
                    :style="
                      missingSize
                        ? `animation-name: missingSize; animation-duration: .5s;`
                        : ''
                    "
                    class="sizes-product"
                    :class="
                      sizeProduct.id == size
                        ? 'size-selected'
                        : sizeProduct.stock == 0
                        ? 'size-no-aviable'
                        : ''
                    "
                    @click="
                      sizeProduct.stock > 0
                        ? selectSizeProduct(sizeProduct.id)
                        : ''
                    "
                    >{{ sizeProduct.name }}</span
                  >
                </div>
              </div>
              <button
                v-if="dataProduct.tipo_prenda_descripcion"
                class="btn-guia-tallas"
                @click="$store.state.showSizeGuide = true"
              >
                Guía de tallas
              </button>
            </div>
            <span v-if="msgError" class="msgError">Selecciona una talla</span>
          </div>
          <div
            v-else
            style="background-color: #FFF4DE"
            class="px-3 py-2 col-xl-10 me-xl-auto me-4 mb-4"
          >
            <p style="color: #654062;" class="mb-0 text-center">
              <b>Este producto no está disponible en este color</b>
            </p>
          </div>
          <message-errors
            v-if="showMsgFewUnits"
            :msg-error="msgFewUnits"
          ></message-errors>
          <div
            v-if="validateColor"
            class="d-none d-md-flex btn-actions-product-detail"
          >
            <button-fav
              :data-product="dataProduct"
              class="btn-fav-product-detail"
            />
            <button
              type=""
              class="btn-primary btn-add-cart-product-detail"
              @click="loading ? '' : agregarProducto()"
            >
              <div v-if="!loading">
                <img src="@/assets/icons/shopping-bag.svg" alt="" />
                {{ size ? 'Agregar a la bolsa' : 'Elige una talla primero' }}
              </div>
              <div v-else class="d-flex justify-content-center">
                Cargando...
                <div class="loader" />
              </div>
            </button>
          </div>
          <div v-if="msgErrorAddProduct" class="text-center me-5">
            <span class="msg-error-add-product">{{ msgErrorAddProduct }}</span>
          </div>
          <p class="text-description-product-detail">
            {{
              dataProduct.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
            <br />
            Pais origen:
            {{
              dataProduct.origen
                ? dataProduct.origen
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                : '*'
            }}
            - Fabricante / Importador:
            {{
              dataProduct.importador
                ? dataProduct.importador
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                : '*'
            }}
            <br />
            <span v-if="dataProduct.composicion">
              Composición:
              {{
                dataProduct.composicion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </span>
          </p>
          <div
            v-if="dataProduct.estrategia_venta"
            class="btn-actions-product-detail estrategia-product-detail"
          >
            <div class="col-9">
              <h5 class="mb-1">{{ dataProduct.estrategia_venta.nombre }}</h5>
              <p class="fs-6 mb-0">
                {{ dataProduct.estrategia_venta.descripcion }}
              </p>
            </div>
            <div class="col-3 d-flex">
              <a
                class="ms-auto"
                :href="dataProduct.estrategia_venta.url_analytics"
              >
                <button style="font-size: .875rem" class="px-4 btn-primary">
                  Ver más
                </button>
              </a>
            </div>
          </div>
        </div>
        <div v-else-if="dataProduct.publicado == 0">
          <p
            v-if="dataProduct.descripcion"
            class="text-description-product-detail"
          >
            {{
              dataProduct.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </p>
          <div class="product-not-stock">
            <p class="fs-6">PRODUCTO NO DISPONIBLE</p>
            <p class="fs-6">
              Este artículo se ha agotado, pero no te preocupes regístrate y te
              avisaremos de nuestros nuevos lanzamientos, ofertas exclusivas y
              descuentos de cumpleaños.
            </p>
            <div class="col-md-10 mx-auto col-xl-8">
              <div class="mb-auto">
                <div class="date-newslatter-modal">
                  <label class="label-text-newlatter-birthday"
                    >Fecha de cumpleaños</label
                  >
                  <select
                    v-model="day"
                    :class="!day && validateDateNewslatter ? 'input-error' : ''"
                    class="payment-input me-3"
                  >
                    <option :value="null">Día</option>
                    <option :value="'01'">1</option>
                    <option :value="'02'">2</option>
                    <option :value="'03'">3</option>
                    <option :value="'04'">4</option>
                    <option :value="'05'">5</option>
                    <option :value="'06'">6</option>
                    <option :value="'07'">7</option>
                    <option :value="'08'">8</option>
                    <option :value="'09'">9</option>
                    <option :value="'10'">10</option>
                    <option :value="'11'">11</option>
                    <option :value="'12'">12</option>
                    <option :value="'13'">13</option>
                    <option :value="'14'">14</option>
                    <option :value="'15'">15</option>
                    <option :value="'16'">16</option>
                    <option :value="'17'">17</option>
                    <option :value="'18'">18</option>
                    <option :value="'19'">19</option>
                    <option :value="'20'">20</option>
                    <option :value="'21'">21</option>
                    <option :value="'22'">22</option>
                    <option :value="'23'">23</option>
                    <option :value="'24'">24</option>
                    <option :value="'25'">25</option>
                    <option :value="'26'">26</option>
                    <option :value="'27'">27</option>
                    <option :value="'28'">28</option>
                    <option :value="'29'">29</option>
                    <option :value="'30'">30</option>
                    <option :value="'31'">31</option>
                  </select>
                  <select
                    v-model="month"
                    :class="
                      !month && validateDateNewslatter ? 'input-error' : ''
                    "
                    class="payment-input ms-3"
                  >
                    <option :value="null">Mes</option>
                    <option :value="'01'">Enero</option>
                    <option :value="'02'">Febrero</option>
                    <option :value="'03'">Marzo</option>
                    <option :value="'04'">Abril</option>
                    <option :value="'05'">Mayo</option>
                    <option :value="'06'">Junio</option>
                    <option :value="'07'">Julio</option>
                    <option :value="'08'">Agosto</option>
                    <option :value="'09'">Septiembre</option>
                    <option :value="'10'">Octubre</option>
                    <option :value="'11'">Noviembre</option>
                    <option :value="'12'">Diciembre</option>
                  </select>
                  <span
                    v-if="day"
                    class="newlatter-error-input"
                    :style="!day && validateDateNewslatter ? 'color: red' : ''"
                    >Día</span
                  >
                  <span
                    v-if="month"
                    class="newlatter-error-input"
                    style="left: calc(50% + 1.5rem);"
                    :style="
                      !month && validateDateNewslatter ? 'color: red' : ''
                    "
                    >Mes</span
                  >
                </div>
                <div class="position-relative">
                  <input
                    id="email"
                    v-model="correo"
                    class="payment-input w-100 mb-3"
                    :class="validateEmailNewslatter ? 'input-error' : ''"
                    placeholder="Correo electrónico"
                    type="email"
                    name="correo"
                  />
                  <span
                    v-if="correo"
                    class="newlatter-error-input"
                    :style="validateEmailNewslatter ? 'color: red' : ''"
                    >correo</span
                  >
                </div>
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <label class="d-flex mx-0 ms-md-1">
                    <input
                      v-model="cel_newslatter"
                      class="tyc-checkbox"
                      type="checkbox"
                      checkmark="checkmark"
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span style="font-size: 14px">Me gustaría recibir SMS.</span>
                </div>
              </div>
              <div
                v-if="cel_newslatter"
                class="input-tel-newslatter w-100 my-3 position-relative"
              >
                <label
                  class="tel-newslatter"
                  :class="validateTelNewslatter ? 'input-error' : ''"
                  :style="validateTelNewslatter ? 'padding: 17px' : ''"
                  >+57</label
                >
                <input
                  v-model="tel"
                  class="payment-input w-100"
                  :class="validateTelNewslatter ? 'input-error' : ''"
                  placeholder="Celular"
                  type="tel"
                  name="celular"
                />
                <span
                  v-if="tel"
                  class="newlatter-error-input"
                  style="left: 4rem"
                  :style="validateTelNewslatter ? 'color: red' : ''"
                  >Celular</span
                >
              </div>
              <div class="text-center w-100 my-3">
                <span class="msgError">{{ msgerrorNewsLatter }}</span>
              </div>
              <div class="mt-auto w-100">
                <div class="col-6 mx-auto pt-2 pb-3">
                  <button class="btn-primary w-100" @click="sendNewsLatter()">
                    Suscribirme
                  </button>
                </div>
                <div class="text-center">
                  <p class="tyc-newslatter mb-0" style="font-size: 1rem">
                    Al suscribirte, aceptas nuestros
                    <router-link
                      style="font-size: 1rem; color: #62285e;"
                      target="_blank"
                      :to="{
                        name: 'Politicas',
                        params: { slug: 'politica-de-privacidad' },
                      }"
                      >Términos y condiciones y Política de tratamiento de
                      datos.</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <video id="video_size_product_detail" width="0" height="0" muted>
        <source :src="dataProduct.video_guia_tallas" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <sizes-guide-product-detail
      v-if="$store.state.showSizeGuide"
      :type-screem="'desktop'"
      :type-of-garment="dataProduct.tipo_prenda_descripcion"
      @closeSizeGuide="$store.state.showSizeGuide = false"
    />
    <add-to-cart />
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav.vue'
import MessageErrors from '@/components/Templates/MessageErrors.vue'
import AddToCart from '@/components/ProductDetail/AddToCart.vue'
import SizesGuideProductDetail from '@/components/ProductDetail/SizesGuideProductDetail.vue'

import { GA4AddToCart, GA4SignUp } from '@/GA4/events'
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { SizesGuideProductDetail, AddToCart, ButtonFav, MessageErrors },
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      missingSize: false,
      msgError: false,
      showMsgFewUnits: false,
      msgFewUnits: '',
      video: '',
      rateNumber: [
        { rate: '5', numRate: 25, percentRate: '50%' },
        { rate: '4', numRate: 22, percentRate: '22.5%' },
        { rate: '3', numRate: 3, percentRate: '2.5%' },
        { rate: '2', numRate: 0, percentRate: '0%' },
        { rate: '1', numRate: 0, percentRate: '0%' },
      ],
      emailNewsLatter: '',
      validateNewsLatter: false,
      correo: '',
      validateEmailNewslatter: false,
      day: null,
      month: null,
      validateDateNewslatter: false,
      tel: '',
      validateTelNewslatter: false,
      cel_newslatter: false,
      showMoreDetailsSize: false,
    }
  },
  computed: {
    ...mapState(['showSizeGuide']),
    ...mapState('cart', ['msgErrorAddProduct']),
    ...mapState('user', ['errorNewsLatter', 'msgerrorNewsLatter']),
    refProduct() {
      return this.dataProduct.referencias.find(e => e.color_id == this.color)
    },
    globalRating() {
      let total = 0.0
      for (var i = 0; i < this.dataProduct.valoraciones.length; i++) {
        total += parseFloat(this.dataProduct.valoraciones[i].puntaje)
      }
      return (
        (total + 225) /
        (this.dataProduct.valoraciones.length + 50)
      ).toFixed(1)
    },
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      if (!this.cel_newslatter) {
        return true
      } else {
        const re = /^3[0-9]{9}/
        return re.test(this.tel) && this.tel.length < 11
      }
    },
    validateColor() {
      let showColor = false
      if (this.refProduct.tallas) {
        this.refProduct.tallas.forEach(e => {
          if (e.stock > 0) {
            showColor = true
          }
        })
      }
      return showColor
    },
  },
  async created() {
    this.sku = this.$route.params.sku
      ? this.$route.params.sku
      : this.$route.params.product.split('-')[0]
    const elThis = this
    this.dataProduct.valoraciones.forEach(function(opinion) {
      let rate = opinion.puntaje
      let findRate = elThis.rateNumber.find(
        element => parseInt(element.rate) == rate,
      )
      let index = elThis.rateNumber.indexOf(findRate)
      elThis.rateNumber[index].numRate += 1
      elThis.rateNumber[index].percentRate =
        (elThis.rateNumber[index].numRate /
          (elThis.dataProduct.valoraciones.length + 50)) *
          100 +
        '%;'
    })
    this.rateNumber = elThis.rateNumber
    if (this.refProduct.outfit) {
      console.log('738 ProductDetailDesktop')
    }

    if(this.size){
      const sizeRefSel = this.refProduct.tallas.find(e => e.id == this.size)
      if(sizeRefSel){
        if(sizeRefSel.alerta_stock){
          this.showMsgFewUnits = true
          if(sizeRefSel.stock == 1){
            this.msgFewUnits = '🔥 ¡Última unidad disponible!'
          }else{
            this.msgFewUnits = '🔥 ¡Últimas '+sizeRefSel.stock+' unidades disponibles!'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('user', ['suscribeDerek']),
    selectSizeProduct(id){
      this.msgError = false
      this.$emit('changeSize',id)
    },
    async sendNewsLatter() {
      if (this.day && this.month && this.validatePhone && this.validateEmail) {
        this.validateEmailNewslatter = false
        this.validateDateNewslatter = false
        this.validateTelNewslatter = false
        await this.suscribeDerek({
          correo: this.correo,
          day: this.day,
          month: this.month,
          sms: this.cel_newslatter ? 1 : 0,
          tel: this.tel ? this.tel : '',
        }).then(() => {
          if (!this.msgerrorNewsLatter) {
            document.cookie = 'email=' + this.correo
            this.$bvModal.show('modal-done-newsletter')
            this.$bvModal.hide('modal-newsletter')
            sendFBQEvent(this, 'Subscribe', {})
            GA4SignUp('NewsLatter')
            this.correo = ''
            this.stepNewslatter += 1
          }
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
    async agregarProducto() {
      if (this.size) {
        let elThis = this
        this.loading = true
        let dataProductAddToCart = {
          size: this.size,
          color: this.color,
          sku: this.dataProduct.producto_sku,
        }
        await this.addToCart(dataProductAddToCart).then(() => {
          if (this.msgErrorAddProduct == '') {
            GA4AddToCart(this.dataProduct.producto_sku)
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.dataProduct.producto_sku,
              value: this.dataProduct.precio,
              content_type: 'product',
              content_name:
                this.dataProduct.categoria_slug.toUpperCase() + ' DEREK',
              content_category: this.dataProduct.categoria_slug.toUpperCase(),
              content_ids:
                this.dataProduct.producto_sku + this.color + this.size,
              currency: 'COP',
            })
            const misCookies = document.cookie.split(';')
            let email = misCookies.find(e => {
              return e.includes('email')
            })
            var deviceType = /iPad/.test(navigator.userAgent)
              ? 't'
              : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                  navigator.userAgent,
                )
              ? 'm'
              : 'd'
            window.criteo_q.push(
              { event: 'setAccount', account: 67539 },
              {
                event: 'setEmail',
                email: email
                  ? email.replace('email=', '')
                  : this.dataUser
                  ? this.dataUser.correo
                  : '',
              },
              { event: 'setSiteType', type: deviceType },
              { event: 'setZipcode', zipcode: '##Zip Code##' },
              { event: 'addToCart', item: [this.dataProduct.producto_sku] },
            )
            elThis.$bvModal.show('modal-add-to-cart')
          }
          this.loading = false
        })
      } else {
        this.msgError = true
        this.missingSize = true
        setTimeout(() => {
          this.missingSize = false
        }, 1000)
      }
    },
    playVideo() {
      let video = document.getElementById('video-producto')
      if (video.requestFullscreen) {
        video.requestFullscreen()
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen()
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen()
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen()
      }
      video.play()
      video.currentTime = 0
      this.listenerExitVideo('video-producto')
    },
    async listenerExitVideo(id_video) {
      let video = document.getElementById(id_video)
      await setTimeout(() => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitCurrentFullScreenElement
        ) {
          this.listenerExitVideo(id_video)
        } else {
          video.pause()
        }
      }, 1000)
    },
    clicTooltip(coordenadas) {
      let productAnalitycs = this.refProduct.outfit.productos_analytics?.find(
        e => e.id == coordenadas.producto_sku,
      )
      this.$emit('selectProductOutfit', coordenadas)
      setTimeout(() => this.$bvModal.show('modal-product-detail-' + coordenadas.producto_sku), 100)
      if (productAnalitycs) {
        // DK-VentaCruzada
        // window.dataLayer.push({
        //   event: 'DK-VentaCruzada',
        //   productoPadre: this.dataProduct.titulo,
        //   productoPadreSKU: this.dataProduct.producto_sku,
        //   item: productAnalitycs,
        //   value: productAnalitycs.price,
        // })
        // this.$gtag.event('productClick', {
        //   ecommerce: {
        //     click: {
        //       actionField: { list: productAnalitycs.category },
        //       products: [
        //         {
        //           name: productAnalitycs.name,
        //           id: productAnalitycs.id,
        //           price: productAnalitycs.price,
        //           brand: productAnalitycs.brand,
        //           category: productAnalitycs.category,
        //           variant: productAnalitycs.variant,
        //           position: productAnalitycs.position,
        //         },
        //       ],
        //     },
        //   },
        // })
      }
    },
    getDataAnalytics(productos) {
      let arrayTemp = []
      productos.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          SKU: product.producto_sku,
          price: product.producto_precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
}
</script>
